//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import meal_plan_icon from "../../assets/images/meal_plan_icon.png";
import bootstrap from "../../assets/bootstrapStyles/bootstrap.min.module.css";
import styles from "../../assets/styles/ManageClients.module.css";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import Request from "axios";
import { BASE_URL } from "../../components/baseURL";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from "datatables.net-dt";
import { JsonToExcel } from "react-json-to-excel";
import Dropdown from "../../components/Dropdown";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function ManageClients() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const dispatch = useDispatch();
  const location = useLocation();
  const active = location?.state;
  const [clientList, setClientList] = useState([]);
  const [filteredList, setFilteredList] = useState(clientList);
  const [planStatus, setPlanStatus] = useState("Plan Status");
  const [excelList, setExcelList] = useState([]);
  const [mask, setMask] = useState(false);
  const navigate = useNavigate();
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  //---------------------------------------------------------Filtering Plan Status Function-----------------------------------------------------------------------//
  function filteringTableStatus() {
    const tab = $("#myTable").DataTable();
    $.fn.dataTable.ext.search.push(function (settings, data) {
      const status = data[7];
      if (status === planStatus || planStatus === "Plan Status") {
        return true;
      }
      return false;
    });
    tab.draw();
    $.fn.dataTable.ext.search.pop();
  }
  //---------------------------------------------------------Filtering Plan Status Function-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to get Client-----------------------------------------------------------------------//
  useEffect(() => {
    Request.get(
      active?.data?.week
        ? `${BASE_URL}client/getTrackerClientsName`
        : `${BASE_URL}client/getClients`
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          setClientList(res?.data?.data);
          setFilteredList(res?.data?.data);
          $(() => {
            $("#myTable").DataTable();
          });

          const excelTemp = res?.data?.data.map((val) => ({
            ClientName: val.Firstname + val.Lastname,
            EmailId: val.EmailId,
            Mobilenumber: val.Mobilenumber,
            ExpiryDate: val.Expirydate,
            PlanStatus: val.Isexpired,
            ManagerName: val.manager_name,
          }));

          if (active && !active?.data?.week) {
            document.getElementById("statusfilter").value = active;
            setPlanStatus(active);
          }
          setExcelList(excelTemp);

          setTimeout(() => {
            styleChanges();
          }, 200);
        } else {
          alert("Error fetching clients");
          console.log("Error fetching clients", res?.data);
        }
      })
      .catch((err) => {
        alert("Error fetching clients", err);
        console.log("Error fetching clients", err);
      });
  }, []);
  //---------------------------------------------------------UseEffect to get Client-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to Filter Table Status-----------------------------------------------------------------------//
  useEffect(() => {
    if (planStatus !== "Plan Status") {
      filteringTableStatus();
    }
  }, [planStatus]);
  //---------------------------------------------------------UseEffect to Filter Table Status-----------------------------------------------------------------------//

  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//
  function styleChanges() {
    document
      .querySelectorAll(".dataTables_length")
      .forEach((el) => (el.style.marginBottom = "40px"));
    document
      .querySelectorAll(".dataTables_wrapper")
      .forEach((el) => (el.style.overflow = "scroll"));
    document
      .querySelectorAll(".dataTables_wrapper")
      .forEach((el) => (el.style.position = "unset"));
    document
      .querySelectorAll(".dataTables_filter")
      .forEach((el) => (el.style.marginRight = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach(
        (el) =>
          (el.style.background = `url(https://static.thenounproject.com/png/101791-200.png) no-repeat`)
      );
    document
      .querySelectorAll(".dataTables_length")
      .forEach((el) => (el.style.marginLeft = "20px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundSize = "22px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundPosition = "386px 15px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.paddingRight = "25px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.backgroundColor = "#f5f5f5"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.width = "420px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.height = "50px"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.border = "unset"));
    document
      .querySelectorAll(".dataTables_filter input")
      .forEach((el) => (el.style.borderRadius = "50px"));
  }
  //---------------------------------------------------------Style Change Function-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to style table-----------------------------------------------------------------------//
  useEffect(() => {
    document
      .querySelectorAll("#myTable")
      .forEach((el) => (el.style.paddingBottom = "50px"));
  }, [mask]);
  //---------------------------------------------------------UseEffect to style table-----------------------------------------------------------------------//

  //---------------------------------------------------------UseEffect to Dispatch Routes  -----------------------------------------------------------------------//
  useEffect(() => {
    dispatch({
      type: "ROUTES",
      payload: active?.data?.week ? "Tracker Updated" : "Manage Clients",
    });
  }, []);
  //---------------------------------------------------------UseEffect to Dispatch Routes  -----------------------------------------------------------------------//

  return (
    <div>
      {mask && (
        <div
          style={{ width: window.innerWidth, height: window.innerHeight }}
          className={styles.mask}
          onClick={() => setMask(false)}
        ></div>
      )}

      <div className={styles.MainDiv}>
        <div className={styles.ManageClientsRow}>
          <div className={styles.FormSection}>
            <div
              className={bootstrap["col-md-2"] + " " + styles.customLablecls}
            >
              <label className={styles.labelDivcls}>Filter:</label>
              <div className={bootstrap.row + " " + styles.MainRowsec}>
                <div
                  className={
                    bootstrap["col-md-3"] + " " + styles.customdropDownRow
                  }
                >
                  <select
                    id="statusfilter"
                    onChange={(e) => setPlanStatus(e.target.value)}
                    className={styles.customSelect}
                    aria-label="Default select example"
                  >
                    <option>Plan Status</option>
                    <option value="Active">Active</option>
                    <option value="Completed">Completed</option>
                    <option value="-">Not Purchased</option>
                    <option value="Dormant">Dormant</option>
                    <option value="Incomplete">Incomplete</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Pending">Pending</option>
                  </select>
                </div>

                <div
                  className={
                    bootstrap["col-md-2"] +
                    " " +
                    styles.customBtnsec +
                    " " +
                    styles.buttonMargin
                  }
                >
                  <button
                    className={styles.customButton}
                    onClick={() => navigate("/newmealplan")}
                  >
                    <img
                      className={styles.planIconcls}
                      src={meal_plan_icon}
                      alt=""
                    />
                    New Meal Plan
                  </button>
                  <JsonToExcel
                    title="Excel Export"
                    data={excelList}
                    fileName="Manager List"
                    btnClassName={styles.customexportButton}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <form></form>

        <table
          id="myTable"
          className={
            bootstrap[("table", "table-striped")] + " " + styles.tableCustomcls
          }
        >
          <thead>
            <tr>
              <th className={styles.Tablefontcls}>Client Name</th>
              <th className={styles.Tablefontcls}>Manager Name</th>
              <th className={styles.Tablefontcls}>Email</th>
              <th className={styles.Tablefontcls}>Mobile No.</th>
              <th className={styles.Tablefontcls}>Joined on</th>
              <th className={styles.Tablefontcls}>Plan Expiration Date</th>
              <th className={styles.Tablefontcls}>Duration</th>
              <th className={styles.Tablefontcls}>Plan Status</th>
              <th className={styles.Tablefontcls}>Action</th>
            </tr>
          </thead>

          <tbody>
            {filteredList.map((val, i) => {
              // console.log("====>", val);
              return (
                <tr key={i}>
                  <td className={styles.Tablefontcls}>
                    {val.Firstname + " " + val.Lastname}
                  </td>
                  <td className={styles.Tablefontcls}>
                    {val.manager_name ? val.manager_name : "-"}
                  </td>
                  <td className={styles.Tablefontcls}>{val.EmailId}</td>
                  <td className={styles.Tablefontcls}>{val.Mobilenumber}</td>
                  <td className={styles.Tablefontcls}>
                    {moment(val.CreatedDate).format("DD MMM YYYY")}
                  </td>
                  <td className={styles.Tablefontcls}>
                    {val.Expirydate
                      ? moment(val.Expirydate).format("DD MMM YYYY")
                      : "-"}
                  </td>
                  <td className={styles.Tablefontcls}>{val.TimeDuration}</td>
                  <td
                    className={styles.Tablefonttdcls}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <label
                      className={styles.ClientStatusActive}
                      style={{
                        backgroundColor:
                          val.Isexpired == 1
                            ? "#FF9CBD"
                            : val.PlanStatus == 1 && val.Isexpired == 0
                            ? "#44E7E3"
                            : val.PlanStatus == 2
                            ? "#FFD952"
                            : val.PlanStatus == 0
                            ? "#FF6434"
                            : val.PlanStatus == 3
                            ? "#838383"
                            : val.PlanStatus == 4
                            ? "#669AFF"
                            : "transparent",
                      }}
                    ></label>
                    {val.Isexpired == 1
                      ? "Completed"
                      : val.PlanStatus == 1 && val.Isexpired == 0
                      ? "Active"
                      : val.PlanStatus == 2
                      ? "Pending"
                      : val.PlanStatus == 0
                      ? "Rejected"
                      : val.PlanStatus == 3
                      ? "Dormant"
                      : val.PlanStatus == 4
                      ? "Incomplete"
                      : "-"}
                  </td>

                  <td className={styles.Tablefontcls}>
                    <Dropdown setMask={setMask} mask={mask} id={i} data={val} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ManageClients;
